import { Component } from '@angular/core';
import { NgModule } from '@angular/core';

@Component({
  selector: 'zahlungen-root',
  templateUrl: './zahlungen.component.html',
  styleUrls: ['./zahlungen.component.css']
})

export class ZahlungenComponent {



}
