import { Haendler } from '../../models';

export class GetHaendler {
    static readonly type = '[Haendler] Get';
    constructor(public payload?: Haendler.HaendlerQueryParams) { }
}

export class CreateUpdateHaendler {
    static readonly type = '[Haendler] Create Update Haendler';
    constructor(public payload: Haendler.HaendlerCreateUpdateDto, public id?: string) { }
}

export class DeleteHaendler {
    static readonly type = '[Haendler] Delete';
    constructor(public id: string) { }
}