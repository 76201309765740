import { CoreModule } from '@abp/ng.core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { ThemeLeptonModule } from '@volo/abp.ng.theme.lepton';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
// import { TableModule } from 'primeng/table';

@NgModule({
  declarations: [],
  imports: [CoreModule, ThemeSharedModule, ThemeLeptonModule, NgbDropdownModule],
  exports: [CoreModule, ThemeSharedModule, ThemeLeptonModule, NgbDropdownModule],
  providers: [],
})
export class SharedModule {}
