import { Injectable } from '@angular/core';
import { RestService } from '@abp/ng.core';
import { Haendler } from '../../store/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HaendlerService {

    constructor(private restService: RestService) { }

    get(params = {} as Haendler.HaendlerQueryParams): Observable<Haendler.Response> {
        return this.restService.request<void, Haendler.Response>({
            method: 'GET',
            url: '/api/app/haendler',
            params
        });
    }

    create(createHaendlerInput: Haendler.HaendlerCreateUpdateDto): Observable<Haendler.Haendler> {
        return this.restService.request<Haendler.HaendlerCreateUpdateDto, Haendler.Haendler>({
            method: 'POST',
            url: '/api/app/haendler',
            body: createHaendlerInput
        });
    }

    getById(id: string): Observable<Haendler.Haendler> {
        return this.restService.request<void, Haendler.Haendler>({
            method: 'GET',
            url: `/api/app/haendler/${id}`
        });
    }

    update(createHaendlerInput: Haendler.HaendlerCreateUpdateDto, id: string): Observable<Haendler.Haendler> {
        return this.restService.request<Haendler.HaendlerCreateUpdateDto, Haendler.Haendler>({
            method: 'PUT',
            url: `/api/app/haendler/${id}`,
            body: createHaendlerInput
        });
    }

    delete(id: string): Observable<void> {
        return this.restService.request<void, void>({
            method: 'DELETE',
            url: `/api/app/haendler/${id}`
        });
    }
}
