import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PermissionGuard } from '@abp/ng.core';
import { GeschaefteComponent } from './ParkSmart Geschäfte/geschaefte.component';
import { HilfeComponent } from './ParkSmart Hilfe/hilfe.component';
import { ParkflaechenComponent } from './ParkSmart Parkflaechen/parkflaechen.component';
import { StammdatenComponent } from './ParkSmart Stammdaten/stammdaten.component';
import { ZahlungenComponent } from './ParkSmart Zahlungen/zahlungen.component';
import { ParkSmartComponent } from './ParkSmart.component';

const routes: Routes = [
    {
        path: 'ParkSmart', 
        component: ParkSmartComponent,
        canActivate: [PermissionGuard],
    },

    {
        path: 'geschaefte',
        component: GeschaefteComponent,
        canActivate: [PermissionGuard],
        //data: { requiredPolicy: 'Parking.Haendler' },
    },
    {
      path: 'hilfe',
      component: HilfeComponent,
      canActivate: [PermissionGuard],
      //data: { requiredPolicy: 'Parking.Haendler' },
  },
  {
    path: 'parkflaechen',
    component: ParkflaechenComponent,
    canActivate: [PermissionGuard],
    //data: { requiredPolicy: 'Parking.Haendler' },
},
{
  path: 'stammdaten',
  component: StammdatenComponent,
  canActivate: [PermissionGuard],
  //data: { requiredPolicy: 'Parking.Haendler' },
},
{
  path: 'zahlungen',
  component: ZahlungenComponent,
  canActivate: [PermissionGuard],
  //data: { requiredPolicy: 'Parking.Haendler' },
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ParkSmartRoutingModule { }
