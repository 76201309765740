import { BasisAlphaCodes } from '../../models';

export class GetBasisAlphaCodes {
    static readonly type = '[BasisAlphaCodes] Get';
    constructor(public payload?: BasisAlphaCodes.BasisAlphaCodesQueryParams) { }
}

export class CreateUpdateBasisAlphaCode {
    static readonly type = '[BasisAlphaCodes] Create Update BasisAlphaCode';
    constructor(public payload: BasisAlphaCodes.BasisAlphaCodeCreateUpdateDto, public id?: string) { }
}

export class DeleteBasisAlphaCode {
    static readonly type = '[BasisAlphaCodes] Delete';
    constructor(public id: string) { }
}