import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDatepickerModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { ParkSmartRoutingModule } from './ParkSmart.routing.module';
import { GeschaefteComponent } from './ParkSmart Geschäfte/geschaefte.component';
import { ZahlungenComponent } from './ParkSmart Zahlungen/zahlungen.component';
import { StammdatenComponent } from './ParkSmart Stammdaten/stammdaten.component';
import { ParkflaechenComponent } from './ParkSmart Parkflaechen/parkflaechen.component';
import { HilfeComponent } from './ParkSmart Hilfe/hilfe.component';
import { ParkSmartComponent } from './ParkSmart.component';
import { HomeComponent } from '../home/home.component';
import { HomeModule } from '../home/home.module';



@NgModule({
  declarations: [
    GeschaefteComponent, 
    ZahlungenComponent, 
    StammdatenComponent, 
    ParkflaechenComponent, 
    HilfeComponent, 
    ParkSmartComponent,

  ],
  imports: [
    CommonModule,
    SharedModule,
    ParkSmartRoutingModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    HomeModule,

  ],

})
export class ParkSmartModule { }
