import { Component } from '@angular/core';
import { NgModule } from '@angular/core';

@Component({
  selector: 'hilfe-root',
  templateUrl: './hilfe.component.html',
  styleUrls: ['./hilfe.component.css']
})

export class HilfeComponent {



}
