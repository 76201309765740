import { Component, OnInit, Input } from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


export interface geschaeftDaten {

  schildnr: number;
  geschaeftsID: number;
  datumErfassung: number;
  geschaeftsArt: string;
  flurname: string;
  parkuhr: number;
  status: string;

}

@Component({
  selector: 'geschaefte',
  templateUrl: './geschaefte.component.html',
  styleUrls: ['./geschaefte.component.css']
})

export class GeschaefteComponent {

  public spaltenTitels: string[] = ["Schild-Nr.", "Geschäfts-ID", "Datum Erfassung", "Geschäfts-Art", "Flurname", "Parkuhr", "Status"];
  public geschaeftDateien: geschaeftDaten[] = [];

  constructor() { }

  ngOnInit() {

    for (let i = 1; i < 8; i++) { 
    this.geschaeftDateien[i-1] = { schildnr: (5*i-0.2*i)*1000, geschaeftsID: i, datumErfassung: 70121, geschaeftsArt: 'Parkverbot', flurname: 'Rebgasse', parkuhr: 2 * i - 0.5* i, status: 'OK' };
    }
}

}
