import { Component, OnInit } from '@angular/core';
import { NgModule } from '@angular/core';

@Component({
  selector: 'parkflaechen-root',
  templateUrl: './parkflaechen.component.html',
  styleUrls: ['./parkflaechen.component.css']
})

export class ParkflaechenComponent {



  constructor() {

    
  }

  ngOnInit() { }



}

