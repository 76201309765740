import { Component } from '@angular/core';
import { NgModule } from '@angular/core';

@Component({
  selector: 'stammdaten-root',
  templateUrl: './stammdaten.component.html',
  styleUrls: ['./stammdaten.component.css']
})

export class StammdatenComponent {



}
