import { State, Action, StateContext, Selector } from '@ngxs/store';
import { GetHaendler, CreateUpdateHaendler, DeleteHaendler  } from '../../actions/stammdaten/haendler.action';
import { Haendler } from '../../models/stammdaten/haendler';
import { HaendlerService } from '../../../stammdaten/haendler/haendler.service';
import { tap } from 'rxjs/operators';

@State<Haendler.State> ({
  name: 'HaendlerState',
  defaults: { haendler: {} } as Haendler.State
})
export class HaendlerState {
    @Selector()
    static getHaendler(state: Haendler.State) {
        return state.haendler.items || [];
    }

	@Selector()
    static getTotalCount(state: Haendler.State): number {
        return state.haendler.totalCount || 0;
    }

    constructor(private haendlerService: HaendlerService) { }
		
    @Action(GetHaendler)
    get({ patchState }: StateContext<Haendler.State>, { payload }: GetHaendler) {
        return this.haendlerService.get(payload).pipe(
            tap(haendlerResponse => {
                patchState({
                    haendler: haendlerResponse,
                });
            }),
        );
    }

    @Action(CreateUpdateHaendler)
    save(ctx: StateContext<Haendler.State>, action: CreateUpdateHaendler) {
        let request;

        if (action.id) {
            request = this.haendlerService.update(action.payload, action.id);
        } else {
            request = this.haendlerService.create(action.payload);
        }
		
        return request;
    }

    @Action(DeleteHaendler)
    delete(ctx: StateContext<Haendler.State>, action: DeleteHaendler) {
        return this.haendlerService.delete(action.id);
    }
}
