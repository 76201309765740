import { ApplicationLayoutComponent } from '@volo/abp.ng.theme.lepton';
import { ABP, AuthGuard, PermissionGuard } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    data: {
      routes: {
        name: '::Menu:Home',
        iconClass: 'fa fa-home',
      } as ABP.Route,
    },
  },
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  //   canActivate: [AuthGuard, PermissionGuard],
  //   data: {
  //     routes: {
  //       name: '::Menu:Dashboard',
  //       iconClass: 'fa fa-line-chart',
  //       requiredPolicy: 'Amaxa.Dashboard.Host || AbpAccount.SettingManagement',
  //     } as ABP.Route,
  //   },
  // },
  {
    path: 'identity',
    loadChildren: () => import('./lazy-libs/identity-wrapper.module').then(m => m.IdentityWrapperModule),
  },
  {
    path: 'account',
    loadChildren: () => import('./lazy-libs/account-wrapper.module').then(m => m.AccountWrapperModule),
  },
  {
    path: 'language-management',
    loadChildren: () =>
      import('./lazy-libs/language-management-wrapper.module').then(m => m.LanguageManagementWrapperModule),
  },
  {
    path: 'saas',
    loadChildren: () => import('./lazy-libs/saas-wrapper.module').then(m => m.SaasWrapperModule),
  },
  {
    path: 'audit-logs',
    loadChildren: () => import('./lazy-libs/audit-logging-wrapper.module').then(m => m.AuditLoggingWrapperModule),
  },
  {
    path: 'identity-server',
    loadChildren: () => import('./lazy-libs/identity-server-wrapper.module').then(m => m.IdentityServerWrapperModule),
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('./lazy-libs/setting-management-wrapper.module').then(m => m.SettingManagementWrapperModule),
  },
  {
    path: 'central',
    component: ApplicationLayoutComponent,
    loadChildren: () => import('./central/central.module').then(m => m.CentralModule),
    canActivate: [AuthGuard],
    data: {
      routes: {
        iconClass: 'fa fa-cog',
        name: '::Menu:Central',
        children: [
          {
            path: 'basisAlphaCodes',
            requiredPolicy: 'Amaxa.BasisAlphaCodes',
            name: '::Menu:BasisAlphaCodes',
          },
        ],
      } as ABP.Route,
    },
  },
  {
    path: 'stammdaten',
    component: ApplicationLayoutComponent,
    loadChildren: () => import('./stammdaten/stammdaten.module').then(m => m.StammdatenModule),
    canActivate: [AuthGuard],
    data: {
      routes: {
        iconClass: 'fa fa-ellipsis-h',
        name: 'Stammdaten',
        children: [
          {
            path: 'haendler',
            requiredPolicy: 'Parking.Haendler',
            name: '::Menu:Haendler',
          },
        ],
      } as ABP.Route,
    },
  },
  {
    path: 'parksmart',
    component: ApplicationLayoutComponent,
    loadChildren: () => import('./ParkSmart/ParkSmart.module').then(m => m.ParkSmartModule),
    canActivate: [AuthGuard],
    data: {
      routes: {
        iconClass: 'fa fa-parking',
        name: 'ParkSmart',
        children: [
          {
            path: 'geschaefte',
            name: '::Geschäfte',
          },
          {
            path: 'zahlungen',
            name: '::Zahlungen',
          },
          {
            path: 'parkflaechen',
            name: '::Parkflächen',
          },
          {
            path: 'stammdaten',
            name: '::Stammdaten',
          },
          {
            path: 'hilfe',
            name: '::Hilfe',
          },
        ],
      } as ABP.Route,
    },
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
