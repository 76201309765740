import { State, Action, StateContext, Selector } from '@ngxs/store';
import { GetBasisAlphaCodes, CreateUpdateBasisAlphaCode, DeleteBasisAlphaCode  } from '../../actions/central/basisAlphaCodes.action';
import { BasisAlphaCodes } from '../../models/central/basisAlphaCodes';
import { BasisAlphaCodesService } from '../../../central/basisAlphaCodes/basisAlphaCodes.service';
import { tap } from 'rxjs/operators';

@State<BasisAlphaCodes.State> ({
  name: 'BasisAlphaCodesState',
  defaults: { basisAlphaCodes: {} } as BasisAlphaCodes.State
})
export class BasisAlphaCodesState {
    @Selector()
    static getBasisAlphaCodes(state: BasisAlphaCodes.State) {
        return state.basisAlphaCodes.items || [];
    }

    @Selector()
    static getTotalCount(state: BasisAlphaCodes.State): number {
        return state.basisAlphaCodes.totalCount || 0;
    }

    constructor(private basisAlphaCodesService: BasisAlphaCodesService) { }

    @Action(GetBasisAlphaCodes)
    get({ patchState }: StateContext<BasisAlphaCodes.State>, { payload }: GetBasisAlphaCodes) {
        return this.basisAlphaCodesService.get(payload).pipe(
            tap(basisAlphaCodesResponse => {
                patchState({
                    basisAlphaCodes: basisAlphaCodesResponse,
                });
            }),
        );
    }

    @Action(CreateUpdateBasisAlphaCode)
    save(ctx: StateContext<BasisAlphaCodes.State>, action: CreateUpdateBasisAlphaCode) {
        let request;

        if (action.id) {
            request = this.basisAlphaCodesService.update(action.payload, action.id);
        } else {
            request = this.basisAlphaCodesService.create(action.payload);
        }

        return request;
    }

    @Action(DeleteBasisAlphaCode)
    delete(ctx: StateContext<BasisAlphaCodes.State>, action: DeleteBasisAlphaCode) {
        return this.basisAlphaCodesService.delete(action.id);
    }
}
