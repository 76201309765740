import { Injectable } from '@angular/core';
import { RestService } from '@abp/ng.core';
import { BasisAlphaCodes } from '../../store/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BasisAlphaCodesService {

    constructor(private restService: RestService) { }

    get(params = {} as BasisAlphaCodes.BasisAlphaCodesQueryParams): Observable<BasisAlphaCodes.Response> {
        return this.restService.request<void, BasisAlphaCodes.Response>({
            method: 'GET',
            url: '/central-api/codes/ax/ba/str',
            params
        });
    }

    create(createBasisAlphaCodeInput: BasisAlphaCodes.BasisAlphaCodeCreateUpdateDto): Observable<BasisAlphaCodes.BasisAlphaCode> {
        return this.restService.request<BasisAlphaCodes.BasisAlphaCodeCreateUpdateDto, BasisAlphaCodes.BasisAlphaCode>({
            method: 'POST',
            url: '/central-api/codes/ax/ba/str',
            body: createBasisAlphaCodeInput
        });
    }

    getById(id: string): Observable<BasisAlphaCodes.BasisAlphaCode> {
        return this.restService.request<void, BasisAlphaCodes.BasisAlphaCode>({
            method: 'GET',
            url: '/central-api/codes/ax/ba/str/${id}'
        });
    }

    // tslint:disable-next-line: max-line-length
    update(createBasisAlphaCodeInput: BasisAlphaCodes.BasisAlphaCodeCreateUpdateDto, id: string): Observable<BasisAlphaCodes.BasisAlphaCode> {
        return this.restService.request<BasisAlphaCodes.BasisAlphaCodeCreateUpdateDto, BasisAlphaCodes.BasisAlphaCode>({
            method: 'PUT',
            url: '/central-api/codes/ax/ba/str/${id}',
            body: createBasisAlphaCodeInput
        });
    }

    delete(id: string): Observable<void> {
        return this.restService.request<void, void>({
            method: 'DELETE',
            url: '/central-api/codes/ax/ba/str/${id}'
        });
    }
}
